import ApiService from './ApiService';

export default class Api extends ApiService {
  async getCleaning(payload) {
    payload.day = payload.day !== 'Invalid Date' ? payload.day : ''
    const { data } = await this._axios.post('/ajax.php?action=getCleaning', {
      ...payload
    });
    return data;
  }

  async systemOptions() {
    const { data } = await this._axios.post('/ajax.php?action=loadSystemOptions');
    return data;
  }

  async responseAllow() {
    const { data } = await this._axios.get('/auth/rest/session?active=true');
    return data;
  }
  async getCleaningId(id) {
    const { data } = await this._axios.post('/ajax.php?action=getCleaning', {
      id: id
    });
    return data;
  }

  // const data = await fetch('/ajax.php?action=getCleaning', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json;charset=utf-8'
  //       },
  //       body: JSON.stringify({readOnly:1,month: '11.2021',tomorrow:0})
  //     });

  // const { data } = await this._axios.get(/ajax.php?action=getCleaning&id=${id});

  // const { data } = await this._axios.put(/ajax.php?action=getCleaningClaims);

  // --------
  async getTypes(payload) {
    const { data } = await this._axios.post('ajax.php?action=getPlacesTypes', {
      params: payload
    });
    return data;
  }

  async getList(payload) {
    const { data } = await this._axios.post('ajax.php?action=getPlaces', payload);
    // const { data } = await this._axios.post('ajax.php?action=getPlaces&wHidden=1');
    return data;
  }

  async getTransport(payload) {
    const { lat, lng } = payload;
    const { data } = await this._axios.get(
      `transportProxy.php?url=stations&lat=${lat}&lng=${lng}&radius=500`
    );
    return data;
  }
  async getTransportId(payload) {
    const { data } = await this._axios.get(`/transport/rest/env/${payload}`);

    return data;
  }

  async getSave(payload) {

    const { data } = await this._axios.post('/ajax.php?action=getCityroads', {
      all: 1,
      limit: -1
    });

    return data;
  }

  async setItem(payload) {
    const { data } = await this._axios.post('ajax.php?action=addNewPlace', payload);
    return data;
  }

  async setRating(payload) {
    const { data } = await this._axios.post('ajax.php?action=operatePublicRating', payload);
    return data;
  }
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    return data;
  }
  async getActivity(payload) {
    const { data } = await this._axios.put(`/ajax.php?action=getCleaningClaims`, payload);

    return data;
  }
  async getActivityId(payload) {
    const { data } = await this._axios.put(`/ajax.php?action=getCleaningClaims`, payload);

    return data;
  }
  async setSaveNew(item) {
    const { data } = await this._axios.post('/ajax.php?action=operateMinimapEvent', {
      action: 'updateJson',
      currentMainMenuNumber: 76,
      item
    });
    return data;
  }
  async getComments(item) {
    const { data } = await this._axios.get(`/ajax.php?action=getGarbageClaimComments&id=${item.id}`, );
    return data;
  }
  async getCommentSave(item) {
    const { data } = await this._axios.post('/ajax.php?action=sendToGarbageClaimComments', {
      id: item.id,
      comment: item.comment
    });
    return data;
  }
  async getUser() {
    const { data } = await this._axios.get(`/auth/rest/user?tokenIndex=0`);

    return data;
  }
  async getUserRating() {
    const { data } = await this._axios.get(`/ajax.php?action=getTotalScore&userId=all`);

    return data;
  }
  async getScore() {
    const { data } = await this._axios.get(`/ajax.php?action=getEvents&s=1`);

    return data;
  }
  async getUserAdd(id) {
    const { data } = await this._axios.get(`/ajax.php?action=operateCleaningParticipate&id=${id}`);

    return data;
  }
  async getCsv() {
    const { data } = await this._axios.get(`/ajax.php?action=getTotalScore&userId=all&asCSV=1`);

    return data;
  }
  async getGeoSearch(params) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', params);/*&address=ростовская&ACAO=1*/

    return data;
  }
  async getLoadSystemOptions() {
    const { data } = await this._axios.post('/ajax.php?action=loadSystemOptions');/*&address=ростовская&ACAO=1*/

    return data;
  }
}
