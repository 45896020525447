<template>
  <div id="app">
    <transition>
      <router-view  v-if="isOpen" />
      <r-notification />
    </transition>
  </div>
</template>
<script>
export default {
  name: 'cleaning-app',
  data() {
    return {
      isOpen: true,
    };
  },
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps') &&
      document.getElementById('vue-yandex-maps').remove();
    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  }
};
</script>
<style>
.align-center {
  align-items: center;
}
.RBulb__badge.fargo.marina.eluno.title.block {
  background-color: unset !important;
}
.RButtonSimple.RModal__close.larishae.transparent.rocky {
  background-color: unset !important;
}
.RButtonAction.rocky.sulguni {
  background-color: unset !important;
}
.RModal__content {
  max-width: 100%;
}
</style>
